import { OpUnitType } from "dayjs";
import type { MemberStatus, ChannelMapDetails } from "./store/types";
import { Size } from "./store/workChannel/types";

const API_BASE_URL = `${process.env.REACT_APP_BACKEND_HOST}${process.env.REACT_APP_API_PATH}`;

export const USER_PATH = `/user`;
export const USER_URL = `${API_BASE_URL}${USER_PATH}`;
export const CREATE_TOKEN_URL = "/user/signin/create-token";
export const LOGIN_URL = "/user/signin";
export const VERIFY_TOKEN = "/user/signin/verify-token";
export const RESEND_TOKEN = "/user/signin/resend-token";
export const CHECK_TOKEN_PATH = `/user/signin/with-token`;
export const LOGIN_DEMO_PATH = `/user/signin/demo`;
export const LOGOUT_PATH = `/user/signout`;
export const LOGOUT_URL = `${API_BASE_URL}${LOGOUT_PATH}`;
export const SIGNUP_PATH = "/user/signup/create-account";
export const GET_PLAN_DETAIL = "/user/get-plan";
export const CONFIRMATION_URL = `${API_BASE_URL}/user/signup/confirm-email`;
export const REST_PASSWORD_REQUEST_URL = `${API_BASE_URL}/user/reset-password-request`;
export const CHECK_RESET_PASSWORD_TOKEN = (token: string) => `/user/reset-password/${token}`;
export const CHECK_INVITATION_TOKEN = (token: string) => `/workspace/check-invitation-token/${token}`;
export const WORKSPACE_MEMBERS_PATH = (id: number) => `/workspace/${id}/members`;
export const WORKSPACE_MANAGERS_PATH = (id: number) => `/workspace/${id}/managers`;
export const GET_WORKSPACE_PATH = `/workspace`;
export const GET_WORKSPACE_FOR_CMS = `/workspace/cms-workspace`;
export const RESET_PASSWORD = `/user/reset-password`;
export const ACCEPT_INVITATION_URL = `/user/accept-invitation`;
export const POST_FCM_DEVICE_TOKEN = `/user/fcm-token`;
export const POST_UPDATE_MAIL_NOTIFICATION = `/user/update-mail-notification`;
export const DELETE_WORKSPACE_BY_NAME = (workspaceId: number) => `/workspace/${workspaceId}`;
export const GET_WORKSPACE_ALL_EMAILS = (workspaceId: number) => `/workspace/${workspaceId}/emails`;
export const GET_ENABLED_WEB_APP_IN_VOICE_CHANNEL = (shortId: string) =>
  `/workspace/voicechannel/enabled-web-app/${shortId}`;
export const POST_DELETE_WORKSPACE_INVITATION = "/workspace/invitation/delete";
export const GET_MULTIPLE_WORKSPACE_MEMBER_COUNT = (workspaceId: number) =>
  `/workspace/member/multiple-workspace/${workspaceId}`;

export const GET_EVENT_LOG_BY_WORKSPACE = (id: number) => `/event-log/${id}`;

export const GET_BILLIG_METHOD_PATH = (workspaceId: number) => `/billing/${workspaceId}/paymentmethods`;
export const GET_BILLING_CLIENT_SECRET_PATH = (workspaceId: number) =>
  `/billing/${workspaceId}/new/paymentmethod/${undefined}`;
export const POST_DETACH_METHOD_PATH = (paymentMethodid: string) => `/billing/${paymentMethodid}/detach/paymentmethod`;
export const POST_CREATE_SUBSCRIPTION_PATH = `/billing/create-subscription`;
export const POST_UPDATE_SUBSCRIPTION_PATH = `/billing/update-subscription`;
export const DELETE_SUBSCRIPTION_CANCEL_PATH = (workspaceId: number) => `/billing/${workspaceId}/cancel-subscription`;
export const GET_BILLING_LOG = (workspaceId: number) => `/billing-log/${workspaceId}`;
export const GET_BILLING_DISPLAY_OR_NOT = (workspaceId: number) => `/billing/${workspaceId}/display`;

export const GET_BILLING_INITIAL_LOAD = (workspaceId: number) => `/billing/initial-load/${workspaceId}`;
export const POST_BILLING_CHECKOUT_SESSION = (workspaceId: number) => `/billing/${workspaceId}/check-out-session`;
export const GET_BILLING_STEPS_INFO = (workspaceId: number) => `/billing/${workspaceId}/steps`;
export const GET_BILLING_PAYMENT_METHOD_SCREEN_INFO = (workspaceId: number) =>
  `/billing/${workspaceId}/initial-paymentmethods`;
export const UPDATE_BILLING_CHANGE_PAYMENT_METHOD = `/billing/update-payment-method`;
export const GET_BILLING_REMAINING_FEE = `/billing/get-remaining-fee`;
export const POST_UPDATE_TRIAL = `/billing/update-trial`;
export const POST_DELETE_TRIAL = `/billing/delete-trial`;
export const GET_INVOICE_EMAIL = (workspaceId: number) => `/billing/${workspaceId}/invoice-email`;
export const POST_UPDATE_INVOICE_EMAIL = `/billing/update/invoice-email`;

export const WORKSPACE_API_BASE = "/workspace";
export const WORKSPACE_URL = `${API_BASE_URL}${WORKSPACE_API_BASE}`;
export const CREATE_INVITE_LINK = (workSpaceShortId: string) =>
  `${API_BASE_URL}/workspace/${workSpaceShortId}/create-invite-link`;
export const CREATE_REFERRAL_LINK = (workSpaceShortId: string) =>
  `${API_BASE_URL}/workspace/${workSpaceShortId}/create-referral-link`;
export const INVITE_EMAILS = (workSpaceShortId: string) => `/workspace/${workSpaceShortId}/invite-emails`;
export const RESEND_INVITATION = `/workspace/resend-invitation`;

// Guest API
export const GUEST_API_ROUTE = "/guest";
export const GUEST_JOIN_PATH = `${GUEST_API_ROUTE}/join`;
export const GET_GUEST_INFO = (shortId: string) => `${GUEST_API_ROUTE}/waiting/data/${shortId}`;
export const GET_WAITING_SHORT_ID = `${GUEST_API_ROUTE}/waiting/shortId`;
export const GET_IS_GUEST_ENABLED = (channelShortId: string) =>
  `${GUEST_API_ROUTE}/is-guest-enabled/${channelShortId}/`;

export const GET_IS_PERMITTED_CHANNEL = `${GUEST_API_ROUTE}/is-permitted-channel`;

// Listener API
export const LISTENER_API_ROUTE = "/listener";
export const GET_REQUIREMENT_LISTENER_JOIN_PATH = (listenerId: string) => `${LISTENER_API_ROUTE}/${listenerId}`;
export const LISTENER_JOIN_PATH = `${LISTENER_API_ROUTE}/join`;
export const LISTENER_SILENCE_AUDIO = `${LISTENER_API_ROUTE}/silence-audio`;

export const API_STATUS_URL = `${API_BASE_URL}/status`;

export const createWorkspaceInviteUrl = (workSpaceShortId: string) =>
  `${WORKSPACE_URL}/${workSpaceShortId}/create-invite-link`;

export const USER_APP_ICON_PATH = (hash: string) => `/user/app-icon/${hash}`;
export const SETTINGS_POST_AVATAR_PATH = "/user/upload-avatar-url";
export const SETTINGS_POST_AVATAR_URL = `${API_BASE_URL}${SETTINGS_POST_AVATAR_PATH}`;
export const SETTINGS_UPDATE_AVATAR_PATH = "/user/update-avatar-url";
export const SETTINGS_UPDATE_AVATAR_URL = `${API_BASE_URL}${SETTINGS_POST_AVATAR_PATH}`;
export const SETTINGS_UPDATE_PROFILE_PATH = `/user/update-profile`;
export const SETTINGS_UPDATE_PROFILE_URL = `${API_BASE_URL}${SETTINGS_UPDATE_PROFILE_PATH}`;
export const SETTINGS_DELETE_ACCOUNT_URL = `${API_BASE_URL}/user`;
export const SETTINGS_UPDATE_EMAIL_URL = `${API_BASE_URL}/user/update-email`;
export const SETTINGS_CONFIRM_UPDATE_EMAIL_URL = `${API_BASE_URL}/user/update-email-confirmation`;
export const SETTINGS_UPDATE_PASSWORD_URL = `/user/update-password`;
export const SETTINGS_UPDATE_AUTHENTICATION = `/user/update-auth`;
export const SETTINGS_DELETE_WORKSPACE_URL = `${API_BASE_URL}/workspace`;
export const SETTINGS_UPDATE_CAL_PROVIDER = `/user/update-cal-provider`;
export const CHECK_CMS_PASSWORD = `/user/check-cms-password`;
export const GET_CMS_PERMISSION = `/user/cms-permission`;
export const UPDATE_CMS_PERMISSION = `/user/update-cms-permission`;
export const UPDATE_USER_WORKSPACE = `/user/update-workspace`;
export const SETTINGS_POST_WORKSPACE_LOGO_PATH = (workspaceId: number) => `/workspace/upload-logo-url/${workspaceId}`;
export const SETTINGS_POST_VOICE_CHANNEL_MAP_PATH = (workspaceId: number) => `/workspace/upload-map-url/${workspaceId}`;
export const WORKSPACE_PLAN_PATH = (workspaceId: number) => `/workspace/plan/${workspaceId}`;
export const VOICE_CHANNELS_IN_WORKSPACE = (workspaceId: number) => `/workspace/${workspaceId}/voice-channels`;
export const WORKSPACE_MEMBER = (workspaceId: number) => `/workspace/${workspaceId}/member`;
export const WORKSPACE_PLAN_VALIDITY_PATH = (workspaceId: number) => `/workspace/plan-validity/${workspaceId}`;
export const ENABLE_WORKSPACE_IP_FILTERING = "/workspace/toggle-ip-filtering";
export const VALIDATE_IP = (workspaceId?: number) => `/workspace/validate-ip/${workspaceId}`;
export const GET_INVITATION_LIST = (workspaceId: number) => `/workspace/invitation-list/${workspaceId}`;
export const GET_WORKSPACE_SIGNED_URL = (workspaceId: number, filename: string) =>
  `/workspace/signed-url/${workspaceId}?filename=${filename}`;

export const IP_WHITE_LIST_ADDRESS = `/ipAddressWhitelist/save-ip-addresss`;
export const DELETE_IP_WHITE_LIST_ADDRESS = `/ipAddressWhitelist/delete-ip-addresss`;
export const ENABLE_ACCESS_FOR_IP_ADDRESS = `/ipAddressWhitelist/enable-access-ip-addresss`;

export const SETTINGS_SLACK_OAUTH_PATH = `/slack/oauth-url`;
export const SETTINGS_SLACK_OAUTH_CONNECT_PATH = `/slack/oauth-connect`;
export const SETTINGS_SLACK_AUTH_DISCONNECT_PATH = `/slack/oauth-disconnect`;

export const POST_MESSAGE_TO_SLACK = `/slack/post-message-to-slack`;
export const SLACK_CHANNELS_PATH = (workspaceId: number) => `/slack/channels/${workspaceId}`;

export const SETTINGS_GOOGLE_OAUTH_PATH = "/google/oauth-url";
export const SETTINGS_GOOGLE_OAUTH_CONNECT_PATH = `/google/oauth-connect`;
export const SETTINGS_GOOGLE_OAUTH_DISCONNECT_PATH = `/google/oauth-disconnect`;

export const SETTINGS_APPLE_OAUTH_PATH = "/apple/auth";

export const TIME_TRACKER_INITIAL_DATA_PATH = "/time-tracker/initial-data";
export const TIME_TRACKER_ACTIVITY_SUMMARY_PATH = "/time-tracker/activity-summary";
export const TIME_TRACKER_WEEKLY_REPORT_PATH = "/time-tracker/weekly-report";
export const TIME_TRACKER_DO_RESUME_PATH = "/time-tracker/do-resume";
export const TIME_TRACKER_EXPORT_REPORT_PATH = "/time-tracker/export-report";
export const TIME_TRACKER_EXPORT_REPORT_GENERATE_PDF_PATH = "/time-tracker/generate-pdf";
export const TRANSCRIPTS_REPORT_PATH = "/transcripts";
export const POST_UDATE_TRANSCRIPT_SHARED_STATUS = `${TRANSCRIPTS_REPORT_PATH}/update/share`;
export const AUDIO_EXTRACTIONS_REPORT_PATH = "/audioExtractions";
export const TIME_TRACKER_ADD_MANUAL_TIME_PATH = "/time-tracker/add-manual-time";
export const TIME_TRACKER_DELETE_TIME_PATH = "/time-tracker/delete-time";
export const TIME_TRACKER_VALIDATE_TIME_PATH = "/time-tracker/validate-time";
export const MANUAL_TIME_LOG_PATH = "/time-tracker/manual-time";
export const INVOICE_SAVE_PATH = "/invoice/save";
export const INVOICE_GET_PATH = "/invoice";
export const INVOICE_GET_INVOICE_NUMBER_PATH = "/invoice/invoice-number";
export const INVOICE_VALIDATE_INVOICE_NUMBER_PATH = "/invoice/validate-invoice-number";
export const INVOICE_DELETE_PATH = "/invoice/delete";
export const INVOICE_COPY_PATH = "/invoice/copy";
export const INVOICE_DOWNLOAD_PATH = "/invoice/download";
export const INVOICE_WORKING_LOG_PATH = "/invoice/working-log";
export const INVOICE_SETTING_PATH = "/invoice/setting";

export const USER_UPDATE_COMPANY_PATH = `/user/update-company`;

export const STT_GET_AUTHORIZATION = `/speech-to-text/get-authorization`;
export const STT_GET_MINUTES_SPENT = `/speech-to-text/get-minutes-spent`;

export const GET_REGIONS_LIST = `/regions`;

export const GET_VP_STT_SERVERS_LIST = `/stt-servers`;

export const PENDING_INVITATION_STATUS_CHECK = `/invitation/pending-status-check`;

export const SETTINGS_CHATWORK_OAUTH_PATH = `/chatwork/oauth-url`;
export const SETTINGS_CHATWORK_OAUTH_CONNECT_PATH = `/chatwork/oauth-connect`;
export const SETTINGS_CHATWORK_AUTH_DISCONNECT_PATH = `/chatwork/oauth-disconnect`;

export const CHATWORK_ROOMS_PATH = `/chatwork/rooms`;
export const VIRTUAL_OBJECT_IMAGE_UPLOAD_PATH = (voiceChannelId: number) =>
  `/virtual-object/upload-object-image/${voiceChannelId}`;
export const VIRTUAL_OBJECT_FILE_UPLOAD_PATH = (voiceChannelId: number) =>
  `/virtual-object/upload-object-file/${voiceChannelId}`;
export const VIRTUAL_OBJECT_FILE_DELETE_PATH = `/virtual-object/delete-object-file`;
export const VIRTUAL_OBJECT_FILE_SIGNED_URL = (voiceChannelId: number, filename: string) =>
  `/virtual-object/object-signed-url/${voiceChannelId}/${filename}`;

export const ONLINE_LOG_DAILY_PATH = "/online-log/daily";
export const ONLINE_LOG_WEEKLY_PATH = "/online-log/weekly";
export const ONLINE_LOG_EXPORT_PATH = "/online-log/export-report";
export const ONLINE_LOG_EXPORT_REPORT_GENERATE_PDF_PATH = "/online-log/generate-pdf";

export const SETTINGS_AZURE_OAUTH_PATH = "/azure/oauth-url";
export const SETTINGS_AZURE_OAUTH_CONNECT_PATH = "/azure/oauth-connect";
export const SETTINGS_AZURE_OAUTH_DISCONNECT_PATH = "/azure/oauth-disconnect";

// Manage Teams
export const GET_WORKSPACE_MEMBERS_WITH_DATA = (workspaceId: number) => `/member/${workspaceId}`;
export const GET_WORKSPACE_MEMBERS_TRACKING_DATA = (workspaceId: number) =>
  `/member/${workspaceId}/timeTrackingDetails`;

export const GET_WORKSPACE_IP_WHITE_LIST_DATA = (workspaceId: number | undefined) =>
  `/ipAddressWhitelist/${workspaceId}/ipaddressDetails`;

export const GET_MEMBER_MANAGE_BASE_INFO = (workspaceId: number) => `/member/${workspaceId}/base-info`;
export const UPDATE_MEMBER_ROLE = (workspaceId: number) => `/member/${workspaceId}/update-role`;
export const UPDATE_MEMBER_WEB_APP = (workspaceId: number) => `/member/${workspaceId}/update-web-app`;
export const DELETE_TEAM_MEMBER = (workspaceId: number, userId: number) => `/member/${workspaceId}/delete/${userId}`;
export const ASSIGN_FLOOR_TO_TEAM_MEMBER = (workspaceId: number, userId: number) =>
  `/member/${workspaceId}/assign-floor/${userId}`;
export const EDIT_PROJECT_FOR_TEAM_MEMBER = (workspaceId: number, userId: number) =>
  `/member/${workspaceId}/edit-project/${userId}`;
export const EDIT_GROUP_FOR_TEAM_MEMBER = (workspaceId: number, userId: number) =>
  `/member/${workspaceId}/edit-group/${userId}`;
export const EDIT_ACCESS_GROUP_FOR_VOICE_CHANNEL = (workspaceId: number, voiceChannelId: number) =>
  `/member/${workspaceId}/edit-access-group/${voiceChannelId}`;
export const ADD_MEMBER_TIME_TRACKING = (workspaceId: number) => `/member/${workspaceId}/timeTracking`;
export const ADD_MEMBER_SCREENSHOTS = (workspaceId: number) => `/member/${workspaceId}/screenshot`;
// Manage Teams - project management
export const CREATE_PROJECT = `/member/project`;
export const DELETE_PROJECT = (projectId: number) => `/member/project/${projectId}/delete`;
export const GET_PROJECTS_BY_WORKSPACEID = (workspaceId: number) => `/member/project/${workspaceId}/projects`;
export const GET_PROJECT_MEMBERS = (projectId: number) => `/member/project/${projectId}/members`;
export const CREATE_PROJECT_MEMBER = (projectId: number) => `/member/project/${projectId}/addMember`;
export const DELETE_PROJECT_MEMBER = (projectId: number) => `/member/project/${projectId}/deleteMember`;
export const UPDATE_PROJECT_NAME = (projectId: number) => `/member/project/${projectId}/updateProjectName`;

// Manage Teams - group management
export const CREATE_GROUP = `/member/group`;
export const DELETE_GROUP = (groupId: number) => `/member/group/${groupId}/delete`;
export const GET_GROUPS_BY_WORKSPACEID = (workspaceId?: number) => `/member/group/${workspaceId}/groups`;
export const GET_GROUP_MEMBERS = (groupId: number) => `/member/group/${groupId}/members`;
export const CREATE_GROUP_MEMBER = (groupId: number) => `/member/group/${groupId}/addMember`;
export const DELETE_GROUP_MEMBER = (groupId: number) => `/member/group/${groupId}/deleteMember`;
export const UPDATE_GROUP_NAME = (groupId: number) => `/member/group/${groupId}/updateGroupName`;
export const UPDATE_GROUP_MEMBERS = `/member/group/updateGroupMembers`;
export const GET_FLOOR_ASSIGNED_MEMBER_IDS = (floorId: number) => `/member/floor/${floorId}/assignedMember`;
export const GET_OWNER_STATUS = `/member/role/owner-status`;
export const POST_UPDATE_OWNER = `/member/role/udpate-owner`;
export const GET_MEMBER_DATA_FOR_TRAMSCRIPT = (workspaceId: number) =>
  `/member/${workspaceId}/transcripts-permission-info`;

export const PUT_UPDATE_RECORDING = (recordingId: number | string) => `/meeting-recordings/${recordingId}`;
export const POST_UPDATE_RECORDING_SHARED_STATUS = `/meeting-recordings/update/share`;
export const GET_MEETING_RECORDING = (
  voiceChannelId: number | string,
  workspaceId: number | string,
  recordingId: number | string,
) => `/meeting-recordings/${voiceChannelId}/${workspaceId}/${recordingId}`;
export const GET_MEETING_RECORDING_LIST = (voiceChannelId: number | string, workspaceId: number | string) =>
  `/meeting-recordings/${voiceChannelId}/${workspaceId}`;
export const DELETE_MEETING_RECORDING = (id: number) => `/meeting-recordings/${id}`;
export const JOIN_MEETING_RECORDING = "/meeting-recordings/join";

export const WORK_CHANNEL_UPLOAD_CUSTOM_BACKGROUND_PATH = (workspaceId: number | string) =>
  `/workspace/upload-custom-background-url/${workspaceId}`;

export const WORK_CHANNEL_UPLOAD_SCREENSHOT_PATH = (workspaceId: number, projectId: number) =>
  `/screenshot/get-presigned-screenshot-url/${workspaceId}/${projectId}`;

export const WORK_CHANNEL_UPLOAD_CUSTOM_OBJECT_PATH = (workspaceId: number | string) =>
  `/workspace/upload-custom-object-url/${workspaceId}`;
export const WORK_CHANNEL_UPLOAD_TEMPLATE_THUMB_PATH = (workspaceId: number) =>
  `/workspace/upload-template-thumb-url/${workspaceId}`;

export const GET_WORKSPACE_FIRST_FLOOR = (workspaceId: number) => `/workspace/${workspaceId}/first-floor`;

export const LIVEKIT_PATH = (shortId: string) => `/livekit/${shortId}`;

// Custom office background
export const WORK_CHANNEL_BASE_INFO = (workspaceId: number, voiceChannelId: number) =>
  `/work-channel/${workspaceId}/${voiceChannelId}/base-info`;

export const GET_FLOOR_OBJECTS = `/work-channel/floor-objects`;
export const GET_FLOOR_OBJECT_CATEGORIES = `/work-channel/floor-object-categories`;
export const CREATE_FLOOR_OBJECT = `/work-channel/create-floor-object`;
export const UPDATE_FLOOR_OBJECT = (objectId: number) => `/work-channel/${objectId}/update-floor-object`;
export const DELETE_FLOOR_OBJECT = (objectId: number) => `/work-channel/${objectId}/delete-floor-object`;

export const GET_WORKSPACE_BACKGROUNDS = `/work-channel/workspace-backgrounds`;
export const CREATE_WORKSPACE_BACKGROUND = `/work-channel/create-workspace-background`;
export const UPDATE_WORKSPACE_BACKGROUND = (workspaceBackgroundId: number) =>
  `/work-channel/${workspaceBackgroundId}/update-workspace-background`;
export const DELETE_WORKSPACE_BACKGROUND = (workspaceBackgroundId: number) =>
  `/work-channel/${workspaceBackgroundId}/delete-workspace-background`;

export const GET_WORK_CHANNEL_TEMPLATES = `/work-channel/work-channel-templates`;
export const UPDATE_WORK_CHANNEL_TEMPLATE = (templateId: number) =>
  `/work-channel/${templateId}/update-work-channel-template`;
export const DELETE_WORK_CHANNEL_TEMPLATE = (templateId: number) =>
  `/work-channel/${templateId}/delete-work-channel-template`;
export const UPDATE_DEFAULT_TEMPLATE = (templateId: number) => `/work-channel/${templateId}/update-default-template`;

export const CREATE_FLOOR_OBJECT_CATEGORY = `/work-channel/create-floor-object-category`;
export const UPDATE_FLOOR_OBJECT_CATEGORY = (categoryId: number) =>
  `/work-channel/${categoryId}/update-floor-object-category`;
export const DELETE_FLOOR_OBJECT_CATEGORY = (categoryId: number) =>
  `/work-channel/${categoryId}/delete-floor-object-category`;

export const GET_CMS_EVENT_LOG = `/cms-event-log`;

export const PROJECT_GROUPS = (projectId: number) => `/member/project-group/${projectId}/groups`;

export const CREATE_OR_UPDATE_TIME_TRACKER_LIMIT = (workspaceId: number) => `/member/${workspaceId}/timeTrackerLimit`;

export const DELETE_EGRESS_RECORDING = (id: number) => `/egress/recordings/${id}`;

export const WORKSPACE_PHRASE_PRESET_PATH = (language: string) => `/phrase/preset/${language}`;
export const GET_WORKSPACE_PHRASE_LIST = (workspaceId: number) => `/phrase/${workspaceId}`;
export const POST_UPDATE_WORKSPACE_PHRASE = "/phrase/update";

export const HOVER_IN_TIMEOUT_DELAY = 800;
export const HOVER_OUT_TIMEOUT_DELAY = 100;
export const ALERT_TIMEOUT_SECONDS_WITHOUT_ACTION = 4;
export const ALERT_TIMEOUT_SECONDS_WITH_ACTION = 10;
export const NOTIFICATION_INFO_TIMEOUT = 3000;
export const NOTIFICATION_WARN_TIMEOUT = 6000;
export const SCREEN_FRAME_RATE = 5;
export const EGRESS_SCREEN_FRAME_RATE = 15;
export const MOBILE_SIZE = 600;
export const STT_MESSAGE_LINES = 4; // Need to match --STT_MESSAGE_LINES from MessageBody.scss
export const APP_IDLE_STATUS_INTERVAL_TIME = 1000 * 10; // 10 sec

export const STT_AUDIO_SAMPLE_RATE = 16_000;
export const MEDIASOUP_AUDIO_SAMPLE_RATE = 96_000;

export const USE_JITSI_MEET_AUDIO_CONSTRAINTS = true;

export const LANGUAGE_OPTIONS = [
  { value: "en", label: "English" },
  { value: "ja", label: "日本語" },
  { value: "vi", label: "Tiếng Việt" },
  { value: "ko", label: "한국어" },
];

export const CAL_PROVIDER_OPTIONS_OBJECT = {
  outlook: { value: "Outlook", label: "Outlook" },
  google: { value: "Google", label: "Google" },
};

export const MEETING_REMINDER_CLOSE_TIMER = 15;
export const IDLE_DURATION_OPTIONS = [1, 3, 5, 10, 20, 30, 60, 0];
export const AUDIBLE_RANGE_OPTIONS = [0.5, 0.6, 0.7, 0.8, 0.9, 1.0, 1.1, 1.3, 1.5, 1.7, 1.9, 2.0];
export const WEBCAM_SIZE_OPTIONS = [1, 2, 3, 4, 5];
export const BROADCASTER_WEBCAM_SIZE_OPTIONS = [0.5, 1, 2, 3, 4, 5];
export const USER_AVATAR_SIZE_OPTIONS = [0.8, 1, 2, 3];
export const BALLOON_TEXT_SIZE_OPTIONS = ["None", 0.8, 1, 1.1, 1.3, 1.5, 2, 3];
export const DISPLAY_NAME_TEXT_SIZE_OPTIONS = ["None", 0.8, 1, 1.1, 1.3, 1.5, 2, 3];
export const MEETING_REMINDER_INTERVAL_OPTIONS = [0, 1, 2, 3, 5, 10];
export const MEETING_DAYS = ["S", "M", "T", "W", "T", "F", "S"];
export const JP_MEETING_DAYS = ["日", "月", "火", "水", "木", "金", "土"];
export const DAY_FULL_NAMES = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const JP_DAY_FULL_NAMES = ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"];
export const DAYS_NOTATION = [
  { notation: "Every Weekday", days: [1, 2, 3, 4, 5] },
  { notation: "Every Weekend", days: [0, 6] },
  { notation: "Everyday", days: [0, 1, 2, 3, 4, 5, 6] },
];
export const JP_DAYS_NOTATION = [
  { notation: "毎週平日", days: [1, 2, 3, 4, 5] },
  { notation: "毎週末", days: [0, 6] },
  { notation: "毎日", days: [0, 1, 2, 3, 4, 5, 6] },
];

export const HEADER_FRONTEND_VERSION_NAME = "Frontend-Version";
export const S_BUCKET_URL = "https://download.voice-ping.com";
export const WINDOW_DOWNLOAD = "voice-ping.exe";
export const LINUX_DOWNLOAD = "voice-ping.deb";
export const MAC_DOWNLOAD = "voice-ping.dmg";
export const GOOGLE_ANALYTICS_ID = throwIfEmpty(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
export const CURRENT_TRANSLATOR = throwIfEmpty(process.env.REACT_APP_CURRENT_TRANSLATOR) as
  | "azure"
  | "deepL"
  | "google";
export const TERMS_SERVICE_JA = "https://docs.voice-ping.com/eb0d4168d167465eb13364fa1f1a7a90";
export const PRIVACY_POLICY_JA = "https://docs.voice-ping.com/2362b468a957452baf0d4bbc3d4a60e4";
export const TERMS_SERVICE_EN = "https://support.voice-ping.com/terms-of-services";
export const PRIVACY_POLICY_EN = "https://support.voice-ping.com/privacy-policy";
export const PRODUCT_UPDATES_URL = "https://voice-ping.com/jp-updates";
export const PRODUCT_UPDATES_URL_EN = "https://voice-ping.com/en-updates";
export const JP_APP_URL = "https://voice-ping.com/";
export const EN_APP_URL = "https://voice-ping.com/en";
export const SUPPORT_EMAIL = "voiceping@p2p-inc.jp";
export const PNG_ICONS_S3_URL = "https://d3oy2xcg0xzaw5.cloudfront.net/";
export const PLAN_SECTION_URL = "https://voice-ping.com/#pricing";
export const VOICE_PING_INSTRUCTION = "https://docs.voice-ping.com/dce89ae10bc54306b7585dd1c36d10a2";
export const OPENAI_API_DATA_USAGE_POLICIES = "https://openai.com/policies/api-data-usage-policies";

function throwIfEmpty(value: string | undefined): string {
  if (!value) {
    throw new Error("Required variable not set");
  }

  return value;
}

export const IS_AUDIOWORKLET_AVAILABLE = window.AudioWorkletNode ? true : false;

export const AudioContext =
  (window as any).AudioContext || // our preferred impl
  (window as any).webkitAudioContext || // fallback, mostly when on Safari
  false; // could not find.

export const VOICEPINGBOT = {
  id: -1,
  name: "VoicePing",
  online: true,
  status: "available" as MemberStatus,
  avatarUrl: `${PNG_ICONS_S3_URL}Colored.svg`,
};

export const LIVE_TRANSLATION_BOT = {
  id: -2,
  name: "LiveTranslation",
  displayName: "LiveTranslation",
  online: true,
  status: "available" as MemberStatus,
  avatarUrl: `${PNG_ICONS_S3_URL}Colored.svg`,
  guestJoinId: null,
};

export const VAD_RESTART_CONST = 3 * 60; //3 minutes

export const HIDE_VAD_TOOLTIP = 10; //seconds

export const activeWindowBlacklist = new Set([
  "Mouse Highlight Overlay", // Loom
  "Loom Control Menu", // Loom
  "New notification", // Desktop notifications
  "Window", // Webex
]);

export const MAX_LENGTH_SELF_INPUT = 125;

export const PRIMARY_WINDOW_ID = 0;

export const USER_RADIUS = 150;

// prettier-ignore
export const PRELOADED_BACKGROUNDS: (ChannelMapDetails & { type: "floor" | "room" })[] = [
  { url: "https://d3oy2xcg0xzaw5.cloudfront.net/background/default-room-1920x1080.jpg", width: 1920, height: 1080, type: "room"  },
  { url: "https://d3oy2xcg0xzaw5.cloudfront.net/background/default-room-2560x1440.jpg", width: 2560, height: 1440, type: "room"  },
  { url: "https://d3oy2xcg0xzaw5.cloudfront.net/background/1920-en.jpg"               , width: 1920, height: 1080, type: "floor" },
  { url: "https://d3oy2xcg0xzaw5.cloudfront.net/background/1920-jp.jpg"               , width: 1920, height: 1080, type: "floor" },
  { url: "https://d3oy2xcg0xzaw5.cloudfront.net/background/2560-en.jpg"               , width: 2560, height: 1440, type: "floor" },
  { url: "https://d3oy2xcg0xzaw5.cloudfront.net/background/2560-jp.jpg"               , width: 2560, height: 1440, type: "floor" },
  { url: "https://d3oy2xcg0xzaw5.cloudfront.net/background/2560-en-2.jpg"             , width: 2560, height: 1440, type: "floor" },
  { url: "https://d3oy2xcg0xzaw5.cloudfront.net/background/2560-jp-2.jpg"             , width: 2560, height: 1440, type: "floor" },
  { url: "https://d3oy2xcg0xzaw5.cloudfront.net/background/2560.jpg"                  , width: 2560, height: 1440, type: "floor" },
  { url: "https://d3oy2xcg0xzaw5.cloudfront.net/background/2560-1.jpg"                , width: 2560, height: 1440, type: "floor" },
];

export const PRELOADED_VIRTUAL_BACKGROUNDS = [
  { url: "/virtualBackgrounds/vbg1.png" },
  { url: "/virtualBackgrounds/vbg2.png" },
  { url: "/virtualBackgrounds/vbg3.png" },
  { url: "/virtualBackgrounds/vbg4.png" },
  { url: "/virtualBackgrounds/vbg5.png" },
  { url: "/virtualBackgrounds/vbg6.png" },
  { url: "/virtualBackgrounds/vbg7.png" },
  { url: "/virtualBackgrounds/vbg8.png" },
];

export const DEFAULT_BACKGRUND = { mainFloor_en: 2, mainFloor_jp: 3, meetingRoom: 0 };
export const DEFAULT_WORKSPACE_BACKGRUND_IDS = { floor: 3, room: 1 };

export const PROJECT_MEMBER_AVATAR_COUNT = 20;
export const GROUP_MEMBER_AVATAR_COUNT = 20;

if (Object.values(DEFAULT_BACKGRUND).some(i => !PRELOADED_BACKGROUNDS[i]))
  throw new Error("Please choose a valid index as default background");

export const NOTIFICATION_WINDOW_AVATAR_SIZE = 70;
export const NOTIFICATION_WINDOW_INITIAL_WIDTH = 140;
export const NOTIFICATION_WINDOW_INITIAL_HEIGHT = 80;
export const NOTIFICATION_WINDOW_WIDTH_FOR_ONE_USER = 120;
export const NOTIFICATION_WINDOW_DEFAULT_POSITION_Y = 45; //Top with margin 40
export const NOTIFICATION_WINDOW_DEFAULT_POSITION_X = window.screen.width - NOTIFICATION_WINDOW_INITIAL_WIDTH; //Right with 0 margin
export const NOTIFICATION_WINDOW_DEFAULT_USERS_LIMIT = 5;
export const NOTIFICATION_WINDOW_MAX_WIDTH = 470;
export const NOTIFICATION_WINDOW_MAX_WIDTH_USERS_LIMIT = 420;
export const NOTIFICATION_WINDOW_VERTICAL_PADDING_WITH_NOTIFICATION = 20;
export const NOTIFICATION_WINDOW_VERTICAL_PADDING_WITHOUT_NOTIFICATION = 24;
export const NOTIFICATION_WINDOW_PADDING = 16;
export const NOTIFICATION_WINDOW_HOVER_PADDING = 40;
export const NOTIFICATION_WINDOW_BORDER_SIZE = 20;
export const NOTIFICATION_WINDOW_ALERT_TIMEOUT = 5_000;
export const INTERNET_ONLINE_STATUS_CHECK = 10_000; //If Internet is down check for online status in every 5 seconds
export const CONTROL_BAR_REF_CLASS = "control-bar-ref-class";
export const MINIMUM_WIDTH_SCREENSHARE_OBJECT = 306;
export const MINIMUM_HEIGHT_SCREENSHARE_OBJECT = 232;
export const LOADER_TIMEOUT_DURATION = 60_000;
export const DEFAULT_EXPAND_TIMEOUT = 500;
export const DEFAULT_SEARCH_MEMBER_HISTORY_COUNT = 5;
export const DEFAULT_SEARCH_MEMBER_HISTORY_PREFIX = "history_";
export const BOTTOM_CONTROL_BAR_HEIGHT = 74;
export const AVATAR_SIZE = 64;
export const PAGINATION_SIZE = 50;

export const GA = {
  url: process.env.REACT_APP_GA_USER_TRAFFIC_URL,
  id: process.env.REACT_APP_GA_USER_TRAFFIC_ID,
  params: process.env.REACT_APP_GA_USER_TRAFFIC_PARAMS,
};

export const DEFAULT_SPEECH_TO_TEXT_LANGUAGE = "ja-JP";
export const SEARCH_QUERY_PARAM_NAME = "search";
export const INCLUDE_GROUP_MEMBERS = "includeGroupMembers";
export const INCLUDED_DELETED_MEMBERS = "deletedMembers";
export const LP_VOICEPING_URL = "https://voice-ping.com/";
export const FAVICON_URL = "https://www.google.com/s2/favicons?domain=";
export const NEAR_BORDER_SCOPE_LIMIT = 5;

//source: http://robotjs.io/docs/syntax#keys
export const RobotjsSupportedKeys = [
  "backspace",
  "delete",
  "enter",
  "tab",
  "escape",
  "up",
  "down",
  "right",
  "left",
  "home",
  "end",
  "pageup",
  "pagedown",
  "f1",
  "f2",
  "f3",
  "f4",
  "f5",
  "f6",
  "f7",
  "f8",
  "f9",
  "f10",
  "f11",
  "f12",
  "command",
  "alt",
  "control",
  "shift",
  "right_shift",
  "space",
  "printscreen",
  "insert",
  "audio_mute",
  "audio_vol_down",
  "audio_vol_up",
  "audio_play",
  "audio_stop",
  "audio_pause",
  "audio_prev",
  "audio_next",
  "audio_rewind",
  "audio_forward",
  "audio_repeat",
  "audio_random",
];

export const HARK_THRESHOLD_DEFAULT = -50;
export const HARK_THRESHOLD_NOISE_REDUCTION = -70;
export const VAD_AUDIO_BUFFER_MIL_SEC = 2000;
export const WORK_CHANNEL_ZOOM_METER_ARRAY = [
  10,
  20,
  30,
  40,
  50,
  60,
  70,
  80,
  90,
  100,
  110,
  120,
  130,
  140,
  150,
  160,
  170,
  180,
  190,
  200,
  300,
  400,
  500,
];
export const WORK_CHANNEL_IMAGE_OBJECT_SIZE_LIMIT = 1000;
export const WORK_CHANNEL_DEFAULT_MAP_PADDING = 200;
export const WORK_CHANNEL_CUSTOM_CATEGORY_ID = 1;

export enum CMSPermission {
  None = 0x0000,
  CmsEventLog = 0x0001,
  CustomOffice = 0x0002,
  ProfileAvatar = 0x0003,
  CmsPermission = 0x0004,
  All = CmsEventLog | CustomOffice | CmsPermission | ProfileAvatar,
}

export const CMSLoginDuration = { amount: 1, unit: "minute" as OpUnitType };
export const MIN_BACKGROUND_RESOLUTION = { width: 1920, height: 1080 };
export const VERTICAL_MIN_BACKGROUND_RESOLUTION = { width: 1080, height: 1920 };
export const WORK_CHANNEL_MAX_FILE_SIZE_MB = 50;
export const WORK_CHANNEL_MAX_FILE_SIZE_BYTES = WORK_CHANNEL_MAX_FILE_SIZE_MB * 1024 * 1024;
export const PROFILE_AVATAR_MAX_FILE_SIZE_MB = 1;
export const PROFILE_AVATAR_MAX_FILE_SIZE_BYTES = PROFILE_AVATAR_MAX_FILE_SIZE_MB * 1024 * 1024;

export enum SideWay {
  SAME_DIRECTION = 0,
  VERTICAL_DIRECTION = 1,
  SLOP_DIRECTION = 2,
}
export const DEFAULT_SIDEBAR_WIDTH = 297;
export const REQUEST_LIMIT = 3;
export const WORK_CHANNEL_OBJECT_MIN_SIZE: Size = { width: 50, height: 50 };
export const ETHERPAD_SERVER_DOMAIN = process.env.REACT_APP_ETHERPAD_SERVER_DOMAIN;
export const WORK_CHANNEL_DEFAULT_GRID_GAP = { min: 16, max: 1000, default: 100 };
export const WORK_CHANNEL_FONT_SIZE_LIMIT = { min: 15, max: 500, default: 20 };
export const WORK_CHANNEL_TEXT_OBJECT_DEFAULT_SIZE = { width: 200, height: 100 };
export const WORK_CHANNEL_GRID_WEIGHT_DEFAULT = 2;

export const VIRTUAL_OBJECT_FILE_SIZE_LIMIT = 500;
export const VIRTUAL_OBJECT_DRAG_DELTA = 2;
export const VIRTUAL_OBJECT_MULTIPLE_GAP = 48;

export const TIME_TRACKER_LIMIT_REACHED = {
  MONTHLY: "MONTHLY",
  WEEKLY: "WEEKLY",
  DAILY: "DAILY",
  SLOTS: "SLOTS",
  DAYS: "DAYS",
};

export const WORKSPACE_CREATE_USER_BY_CSV = (workspaceId: number) => `/workspace/${workspaceId}/create-user/csv`;
export const WORKSPACE_CREATE_USER_BY_MANUAL = (workspaceId: number) => `/workspace/${workspaceId}/create-user/manual`;

export const MAX_DAY_AUTO_TEXT_TRANSLATION = 7;

//CMS Profile Avatar
export const GET_AVATARS = `/cms-avatar`;
export const GET_AVATAR_CATEGORIES = `/cms-avatar/avatar-category`;
export const CREATE_AVATAR_CATEGORY = `/cms-avatar/create-avatar-category`;
export const CREATE_AVATAR = `/cms-avatar/create-avatar`;
export const UPDATE_AVATAR = (objectId: number) => `/cms-avatar/${objectId}/update-avatar`;
export const DELETE_AVATAR = (objectId: number) => `/cms-avatar/${objectId}/delete-avatar`;

export const UPDATE_AVATAR_CATEGORY = (categoryId: number) => `/cms-avatar/${categoryId}/update-category`;
export const DELETE_AVATAR_CATEGORY = (categoryId: number) => `/cms-avatar/${categoryId}/delete-category`;

export const ALLOWED_SOCKET_EVENT_FOR_LISTENER = [
  "workspace/initial-load-request",
  "user/update-chat-display-language-request",
  "user/silence",
  "user/update-personal-speaking-language-request",
  "textChannel/translate-message-request",
  "textChannel/new-message-request",
  "voiceChannel/join",
  "voiceChannel/request-knock-private-meeting",
  "voiceChannel/request-resend-knock-private-meeting",
  "voiceChannel/request-cancel-knock",
  "speechToText/spend",
];

export const TEXT_FONT_SIZE = { min: 10, max: 100 };

export const DEFAULT_PLAY_SPEED = 1.1;
export const PLAY_SPEED_LIST = [0.85, 1.1, 1.25, 1.5];

export const EMOJI_OF_LANGUAGES: { [key: string]: string } = {
  ja: "🇯🇵",
  en: "🇺🇸",
  "zh-CN": "🇨🇳",
  "zh-TW": "🇹🇼",
  da: "🇩🇰",
  vi: "🇻🇳",
  fr: "🇫🇷",
  de: "🇩🇪",
  ar: "🇸🇦",
  el: "🇬🇷",
  gu: "🇮🇳",
  hi: "🇮🇳",
  is: "🇮🇸",
  id: "🇮🇩",
  ga: "🇮🇪",
  it: "🇮🇹",
  km: "🇰🇭",
  kn: "🇮🇳",
  ko: "🇰🇷",
  lo: "🇱🇦",
  lv: "🇱🇻",
  lt: "🇱🇹",
  mk: "🇲🇰",
  mn: "🇲🇳",
  ms: "🇲🇾",
  mt: "🇲🇹",
  mr: "🇮🇳",
  my: "🇲🇲",
  ne: "🇳🇵",
  no: "🇳🇴",
  fa: "🇮🇷",
  pl: "🇵🇱",
  pt: "🇵🇹",
  ro: "🇷🇴",
  ru: "🇷🇺",
  sr: "🇷🇸",
  sk: "🇸🇰",
  sl: "🇸🇮",
  es: "🇪🇸",
  sw: "🇰🇪",
  ta: "🇮🇳",
  te: "🇮🇳",
  th: "🇹🇭",
  tr: "🇹🇷",
  uk: "🇺🇦",
  af: "🇿🇦",
};

export const DEFAULT_MEETING_APPS = [
  {
    label: "Zoom",
    value: "Zoom",
    regexPatterns: [/Zoom (Meeting|ミーティング|회의|cuộc họp|会议|會議)/],
    icon: PNG_ICONS_S3_URL + "Zoom.svg",
  },
  { label: "Google Meet", value: "Google Meet", regexPatterns: [/^Meet - /], icon: PNG_ICONS_S3_URL + "Meet.svg" },
  {
    label: "Teams",
    value: "Teams",
    regexPatterns: [/^(?!(Chat|チャット|채팅|Trò chuyện|聊天)).*\| Microsoft Teams/, /^Microsoft Teams classic$/],
    icon: PNG_ICONS_S3_URL + "Teams.svg",
  },
  { label: "Skype", value: "Skype", regexPatterns: [/^Skype$/], icon: PNG_ICONS_S3_URL + "Skype.svg" },
];

export const SCREEN_SHARE_MEDIA_CONSTRAINTS: Record<string, MediaTrackConstraints> = {
  vga: { width: { ideal: 1024 }, height: { ideal: 768 }, frameRate: { ideal: 5 } },
  hd: { width: { ideal: 1280 }, height: { ideal: 720 }, frameRate: { ideal: 5 } },
  fullhd: { width: { ideal: 1920 }, height: { ideal: 1080 }, frameRate: { ideal: 5 } },
};

export const LISTENER_MODE_QUERY_NAME = "mode";

export enum LISTENER_MODE_QUERY_TYPE {
  NORMAL = "",
  AUDIO_EXTRACTION = "audioExtraction",
  MOBILE = "mobile",
}

export const cursorColors = [
  "#ff0000",
  "#ff8000",
  "#ffcc00",
  "#80ff00",
  "#00cc00",
  "#00ffff",
  "#0080ff",
  "#0000ff",
  "#8000ff",
  "#ff00ff",
];

export const DEFAULT_TRANSLATION_FREQUENCY = 6;
export const LOW_TRANSLATION_FREQUENCY = 12;
export const HIGH_TRANSLATION_FREQUENCY = 3;
