export const channels = {
  APP_DOWNLOAD_INFO: "app-download-info",
  CONFIG_LOAD: "config-load",
  I18N_SETUP: "i18n-setup",
  CHECK_TOKEN: "check-token",
  APP_UPDATE: "app-update",
  APP_VERSION: "app-version",
  APP_LATEST_VERSION: "app-latest-version",
  UPDATE_APP: "update-app",
  APP_UPDATED: "app-updated",
  VOICE_CHANNEL_CONNECTED: "voice-channel-connected",
  VOICE_CHANNEL_DISCONNECTED: "voice-channel-disconnected",
  RELOAD_APP: "reload-app",
  GET_IDLE_TIME: "get-idle-time",
  SET_LOGIN_SETINGS: "set-login-settings",
  GET_ACTIVE_WIN_SOURCES: "get-active-win-sources",
  GET_SCREENSHARE_SOURCES: "get-screenshare-sources",
  WILL_APP_LAUNCH_AT_LOGIN: "will-app-launch-at-login",
  CHECK_PERMISSION: "check-permission",
  SHOW_APP: "show-app",
  USER_AWAY: "user-away",
  USER_RESUME: "user-resume",
  START_WORKING: "start-working",
  STOP_WORKING: "stop-working",
  STARTED_WORKING: "started-working",
  STOPPED_WORKING: "stopped-working",
  SET_MENU_TIMER: "set-menu-timer",
  SHOULD_CLOSE: "should-close",
  CONFIRM_CLOSE: "confirm-close",
  REDIRECTION_URL: "redirection-url",
  STOP_SHARE: "stop-share-screen",
  START_SHARE: "start-share-screen",
  OPEN_WINDOW: "open-window",
  CLOSE_WINDOW: "close-window",
  MINIMIZE_WINDOW: "minimize-window",
  MAXIMIZE_WINDOW: "maximize-window",
  UNMAXIMIZE_WINDOW: "unmaximize-window",
  OPEN_DIARY: "open-diary",
  WEB_LOAD_SUCCESS: "web-load-success",
  SET_USER: "set-user",
  UPDATE_AVAILABLE: "update-available",
  INTERNET_CONNECTIVITY: "internet-connectivity",
  SIMULATE_USER_INPUT: "simulate-user-input",
  FOCUS_MAIN_WINDOW: "focus-main-window",
  CLOSE_NOTIFICATION: "close-notification",
  LOADED_SUB_WINDOW: "loaded-sub-window",
  WINDOW_MAXIMIZED: "window-maximized",
  SHOW_NOTIFICATIONS_WINDOW: "show-notifications-window",
  HIDE_NOTIFICATIONS_WINDOW: "hide-notifications-window",
  SET_APP_FOCUS_STATUS: "set-app-focus-status",
  SYNC_NOTIFICATION_WINDOW_POSITION: "sync-notification-window-position",
  HANDLE_INVITE_RESPONSE: "handle-invite-response",
  HANDLE_REMINDER_RESPONSE: "handle-meeting-reminder-response",
  START_ONLINE_LOG: "start-online-log",
  STOP_ONLINE_LOG: "stop-online-log",
  SHOW_STOP_SHARING_WINDOW: "show-stop-sharing-window",
  HIDE_STOP_SHARING_WINDOW: "hide-stop-sharing-window",
  GET_CPU_INFO: "get-cpu-info",
  REQUEST_REMOTE_CONTROL: "request-desktop-control",
  MOVE_REMOTE_DESKTOP_MOUSE: "move-remote-desktop-mouse",
  TOGGLE_REMOTE_DESKTOP_MOUSE: "toggle-remote-desktop-mouse",
  SCROLL_REMOTE_DESKTOP_MOUSE: "scroll-remote-desktop-mouse",
  HANDLE_KEYBOARD_EVENT: "handle-keyboard-event",
  SHOW_STOP_REMOTE_CONTROL_WINDOW: "show-stop-remote-control-window",
  HIDE_STOP_REMOTE_CONTROL_WINDOW: "hide-stop-remote-control-window",
  SHOW_SCREENSHARE_OVERLAY_WINDOW: "show-screenshare-overlay-window",
  HIDE_SCREENSHARE_OVERLAY_WINDOW: "hide-screenshare-overlay-window",
  CLEAR_NOTIFICATIONS: "clear-notifications",
  CLEAR_UNREAD_MESSAGE_IDS: "clear-unread-message-ids",
  CAPTURE_SCREENSHOT: "capture-screenshot",
  STOP_SCREENSHOT: "stop-screenshot",
  START_SCREENSHOT: "start-screenshot",
  UPDATE_TRACKING: "update-tracking",
  DOWNLOAD_URL: "download-url",
  UPDATE_DOWNLOAD_STATUS: "update-download-status",
  ALLOW_DRAWING_ON_OVERLAY_WINDOW: "allow-drawing-on-overlay-window",
  DISABLE_DRAWING_ON_OVERLAY_WINDOW: "disable-drawing-on-overlay-window",
  JOIN_CHANNEL_FROM_SUBSCRIBING_KNOCK: "join-channel-from-subscribing-knock",
  SHOW_TEXT_CHAT_WINDOW_FOR_AUDIO_EXTRACTION: "show-text-chat-window-for-audio-extraction",
  HIDE_TEXT_CHAT_WINDOW_FOR_AUDIO_EXTRACTION: "hide-text-chat-window-for-audio-extraction",
  DESTROY_TEXT_CHAT_WINDOW_FOR_AUDIO_EXTRACTION: "destroy-text-chat-window-for-audio-extraction",
  SHOW_TEXT_CHAT_WINDOW: "show-text-chat-window",
  HIDE_TEXT_CHAT_WINDOW: "hide-text-chat-window",
  SYNC_TEXT_CHAT_WINDOW_POSITION: "sync-text-chat-window-position",
  SHOW_LOGIN_WINDOW: "show-login-window",
  HIDE_LOGIN_WINDOW: "hide-login-window",
  LOGIN: "login",
  STORE_IFRAME_PASSWORD: "store-iframe-password",
  REMOVE_IFRAME_PASSWORD: "remove-iframe-password",
  HANDLE_MISSED_CALL_RESPONSE: "handle-missed-call-response",
  SHOW_SUBTITLE_WINDOW: "show-subtitle-window",
  HIDE_SUBTITLE_WINDOW: "hide-subtitle-window",
  BLUR_HANDLER_FOR_TEXT_CHAT_WINDOW: "blur-handler-for-text-chat-window",
} as const;
